import { Link } from "gatsby";
import React, { Component } from "react";

class BelowFooter extends Component {
    render() {
        return (
            <section className="show-on-scroll d-more-infos sticky bottom-0 bg-celeste">
                <Link to='decodeup-globe-presents'>
                    <div className="md:px-90 lg:px-0 pt-8 pb-34 d-more-infos-wrap container mx-auto text-center">
                        <h5 className="pb-0-2 text-h5 font-poppings-bold">Generating global results for more than 70 international partners </h5>
                        <p className="text-p4 font-worksans-normal">United States (USA), United Kingdom (UK), Singapore, Germany, Canada, Australia, Ireland, Dublin, New Zealand, Netherlands, Norway, United Arab Emirates (UAE), Saudi Arabia, Qatar, Finland, Mexico, Switzerland, Spain, France and others.</p>
                    </div>
                </Link>
            </section>
        )
    }
}

export default BelowFooter;