import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Headroom from "react-headroom";
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Location } from '@reach/router'
import { Fade } from 'react-reveal'

// Images
import DarkLogo from "../images/uploads/decodeup-logo-light-theme.svg"
import LightLogo from "../images/uploads/decodeup-logo-dark-theme.svg"

const activeStyles = {
  color: '#00AEEF'
}
const activebtnStyles = {
  background: '#00AEEF',
  color: '#ffffff'
}

const Header = ({ sendStateToHeader }) => {
  const [isTheme, setIsTheme] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isSubMenuIndex, setIsSubMenuIndex] = useState(0);

  useEffect(() => {
    var subItem = document.querySelectorAll(".dropdown-item");
    for (var i = 0; i < subItem.length; i++) {
      if (subItem[i].classList.contains('active')) {
        subItem[i].parentNode.parentNode.parentNode.classList.add("active");
      }
    }
    const isTheme = JSON.parse(localStorage.getItem("dark"));
    const label = isTheme ? `dark` : `light`;
    document.body.classList.add(label, "head-animate");
    if (isTheme) {
      setIsTheme(true)
      sendStateToHeader(true);
    } else {
      setIsTheme(isTheme)
      sendStateToHeader(isTheme);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [isTheme]);

  const handleScroll = (event) => {
    var e1 = document.getElementById("navbar");
    const height = document.getElementById("navmenu").clientHeight;

    if (window.scrollY > height) {
      if (e1.classList.contains("mobile-nav-active")) {
        setIsMenuActive(false)
        e1.classList.remove("mobile-nav-active");
        document.getElementById("hambar").classList.remove("open");
        document.getElementById("navmenu").classList.remove("on");
        document.getElementById("nav-h").classList.remove("nav-h");
      }
    }
  }

  const toggleTheme = () => {
    let theme = !isTheme;
    localStorage.setItem("dark", JSON.stringify(theme));
    setIsTheme(theme)
    sendStateToHeader(theme);

    const label = theme ? `dark` : `light`;
    if (document.body.classList.contains("dark")) {
      document.body.classList.remove("dark");
      document.body.classList.add(label);
    } else {
      document.body.classList.remove("light");
      document.body.classList.add(label);
    }
  };

  const activeMobilemenu = () => {
    setIsMenuActive(!isMenuActive);
  }

  const activeMobilesubmenu = (index, e) => {
    const dataId = e.currentTarget.dataset.id;
    if (dataId == index) {
      setIsSubMenuIndex(0);
    } else {
      setIsSubMenuIndex(index);
    }
  }

  const theme = isTheme;
  const label = theme ? `Activate light mode` : `Activate dark mode`;
  const hambtn = isMenuActive ? "open" : "";
  const mobMenu = isMenuActive ? "on" : "";
  const navH = isMenuActive ? "navH" : "";
  const activeMobMenu = isMenuActive ? "mobile-nav-active" : "";

  return (
    <>
      <Headroom>
        <header id="navbar" className={`f-top activeMobMenu`}>
          <Fade top distance="20px">
            <div className="container mx-auto container-full">
              <div className="w-full md:pt-2-2 md:pb-1-2 lg:pt-4-2 lg:pb-3-2 2md:flex 2md:items-center 2md:justify-between">
                <div className="meta-overlay w-full 2md:w-1-50">
                  <div className="w-full px-3-3 2md:px-0 meta-wrapper flex items-center justify-between">
                    <div className="w-1-09 md:w-1-16 h-6-5 md:h-10 2md:w-1-50">
                      <Link to="/" className="block">
                        <div className="dark-logo hidden ">
                          <img className="h-full w-full" src={LightLogo} />
                        </div>
                        <div className="light-logo hidden">
                          <img className=" h-full w-full " src={DarkLogo} />
                        </div>
                      </Link>
                    </div>
                    <div className="flex justify-end items-center w-2/5 2md:hidden block ">
                      <div className="sunmoonM w-6 h-6  ">
                        <div
                          onClick={toggleTheme}
                          className={`darklighttoggle ${isTheme ? "darklighttoggle2" : ""
                            } `}
                        ></div>
                      </div>
                      <nav
                        className={`hamburger relative pointer-cursor  ${hambtn}`}
                        id="hambar"
                        onClick={activeMobilemenu}
                      >
                        <ul id="menu_icon">
                          <li className="menu-line menu-line-1"></li>
                          <li className="menu-line menu-line-2"></li>
                          <li className="menu-line menu-line-3"></li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="nav-wrapper w-full grow">
                  <div className={`nav-content ${navH}`} id="nav-h">
                    <div
                      className={`2md:flex 2md:items-center 2md:justify-between navmenu-inner ${mobMenu}`}
                      id="navmenu"
                    >
                      <div className="grow ">
                        <ul
                          className="pt-3-1 pb-3-2 2md:py-0 2md:mx-0 font-worksans-normal list-reset 2md:flex 2md:items-center 2md:justify-center"
                          role="menubar"
                        >
                          <li
                            className={`relative nav-item nav-active ${isSubMenuIndex === 1 ? "active" : ""
                              }`}
                            data-id={isSubMenuIndex}
                            role="menuitem"
                            onClick={e => activeMobilesubmenu(1, e)}
                          >
                            <button className="block pt-2-2 pb-2-3 px-2-2 2md:pl-0 2md:py-0 2md:pr-4 lg:pr-5-1 mx-auto focus:outline-none text-term-primary text-rp3 xl:text-p2 ">
                              <span>Services</span>
                              <svg
                                className="inline-block ml-1-1 down-arrow stroke-current text-term-primary"
                                width="10"
                                height="7"
                                viewBox="0 0 11 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.3125 5.15625L1.3125 1.15625"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9.3125 1.15625L5.3125 5.15625"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            <div
                              className={`2md:pt-6-4 2md:-ml-6-1 dropdown-menu submenu--s 2md:h-auto 2md:invisible 2md:absolute 2md:flex 2md:flex-col 2md:items-center 2md:w-2-72 `}
                            >
                              <svg className="hidden 2md:block submenu-indicator1 absolute hidden w-6 h-3 fill-current 2md:block">
                                <path d="M24 10H0L9.879.817c1.171-1.09 3.07-1.09 4.242 0L24 10z"></path>
                              </svg>
                              <div className="2md:pt-2-2 2md:pb-4-3 2md:pl-6-3 2md:pr-6-5 dropdown-menu-items relative w-full 2md:shadow-3 overflow-hidden 2md:bg-background-primary">
                                <Link
                                  to="/website-design-development"
                                  activeStyle={activeStyles}
                                  className="dropdown-item"
                                  activeClassName="active"
                                >
                                  Website Design & Development
                                </Link>
                                <Link
                                  to="/mobile-applications"
                                  activeStyle={activeStyles}
                                  className="dropdown-item"
                                  activeClassName="active"
                                >
                                  Mobile Applications
                                </Link>
                                <Link
                                  to="/custom-web-applications"
                                  activeStyle={activeStyles}
                                  className="dropdown-item"
                                  activeClassName="active"
                                >
                                  Custom Web Applications
                                </Link>
                                <Link
                                  to="/graphic-design"
                                  activeStyle={activeStyles}
                                  className="dropdown-item"
                                  activeClassName="active"
                                >
                                  Graphic Design
                                </Link>
                                <Link
                                  to="/digital-marketing"
                                  activeStyle={activeStyles}
                                  className="dropdown-item"
                                  activeClassName="active"
                                >
                                  Digital Marketing
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li className="2md:px-4 lg:px-5-1 text-center nav-active ">
                            <Link
                              to="/case-studies"
                              activeStyle={activeStyles}
                              className="pt-2-2 pb-2-3 px-2-2 2md:px-0 2md:py-0 inline-block text-term-primary text-rp3 xl:text-p2"
                            >
                              <span> Case Studies </span>
                            </Link>
                          </li>
                          <li className="2md:px-4  lg:px-5-1 text-center nav-active  ">
                            <Link
                              to="/our-process"
                              activeStyle={activeStyles}
                              className="pt-2-2 pb-2-3 px-2-2 2md:px-0 2md:py-0 inline-block text-term-primary text-rp3 xl:text-p2"
                            >
                              <span> Our Process </span>
                            </Link>
                          </li>
                          <li className="2md:px-4  lg:px-5-1 text-center nav-active  ">
                            <Link
                              to="/testimonials"
                              activeStyle={activeStyles}
                              className="pt-2-2 pb-2-3 px-2-2 2md:px-0 2md:py-0 inline-block text-term-primary text-rp3 xl:text-p2"
                            >
                              <span> Testimonials</span>
                            </Link>
                          </li>
                          <li
                            className={`relative nav-item nav-active ${isSubMenuIndex == 2 ? "active" : ""
                              }`}
                            data-id={isSubMenuIndex}
                            role="menuitem"
                            onClick={e => activeMobilesubmenu(2, e)}
                          >
                            <button className="pt-2-2 pb-2-3 px-2-2 2md:pr-0 2md:py-0 2md:pl-4 lg:pl-5-1 mx-auto group block focus:outline-none text-term-primary text-rp3 xl:text-p2">
                              <span>About</span>
                              <svg
                                className="inline-block ml-1-1 down-arrow stroke-current text-term-primary"
                                width="10"
                                height="7"
                                viewBox="0 0 11 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.3125 5.15625L1.3125 1.15625"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9.3125 1.15625L5.3125 5.15625"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            <div
                              className={`2md:pt-6-4 2md:-ml-6-1 dropdown-menu submenu--a 2md:invisible 2md:h-auto 2md:absolute 2md:flex 2md:flex-col 2md:items-center 2md:w-1-50`}
                            >
                              <svg className="hidden 2md:block submenu-indicator2 absolute hidden w-6 h-3 fill-current 2md:block">
                                <path d="M24 10H0L9.879.817c1.171-1.09 3.07-1.09 4.242 0L24 10z"></path>
                              </svg>
                              <div
                                id="about_sub_menu"
                                className="2md:pt-2-2 2md:pb-4-3 2md:pl-6-3 2md:pr-6-5 dropdown-menu-items relative w-full 2md:shadow-3 overflow-hidden 2md:bg-background-primary"
                              >
                                <Link
                                  to="/about"
                                  activeStyle={activeStyles}
                                  activeClassName="active"
                                  className="dropdown-item"
                                >
                                  About Us
                                </Link>
                                <Link
                                  to="/our-team"
                                  activeStyle={activeStyles}
                                  activeClassName="active"
                                  className="dropdown-item"
                                >
                                  Our Team
                                </Link>
                                <Link
                                  to="/award"
                                  activeStyle={activeStyles}
                                  activeClassName="active"
                                  className="dropdown-item"
                                >
                                  Awards
                                </Link>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="pb-4-2 2md:pb-0 flex items-center">
                        <div className="sunmoon w-6 h-6 hidden 2md:block">
                          <div
                            onClick={toggleTheme}
                            className={`darklighttoggle ${isTheme ? "darklighttoggle2" : ""
                              } `}
                          ></div>
                        </div>

                        <div className="connectBtn mx-auto">
                          <Link
                            to="/get-in-touch"
                            activeStyle={activebtnStyles}
                            className="2md:ml-2-2 lg:ml-3-3 px-4-3 pt-2-1 pb-2-2 lg:px-6-2 lg:py-2-3 2md:py-2 2md:px-6-2 font-poppings-bold inline-block text-cyan text-rh5 2md:text-rp3 lg:text-h5 border border-cyan hover:text-white hover:bg-cyan transition-all duration-300 ease-in-out delay-0"
                          >
                            Connect With Us
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </header>
      </Headroom>
    </>
  )
}


export default Header
