import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Location } from "@reach/router"

// components
import Notice from "./notice"
import Header from "./header"
import Footer from "./footer"
import MoreInfo from "./below-footer"

import "../styles/layout.css"
import "../styles/layout2.css"
import "../styles/casestudy.css"
import "../styles/portfolio.css"
import "../styles/svganimation.css"
import "../styles/homepage.css"

// cast study
import "../styles/cstmcasestudiescss/cellaed.css"
import "../styles/cstmcasestudiescss/greenwoodbus.css"
import "../styles/cstmcasestudiescss/flowLab.css"
import "../styles/cstmcasestudiescss/bundle.css"
import "../styles/cstmcasestudiescss/usamahoni.css"
import "../styles/cstmcasestudiescss/cordialsa.css"
import "../styles/cstmcasestudiescss/wacomstore.css"
import "../styles/cstmcasestudiescss/frattire.css"
import "../styles/cstmcasestudiescss/realtyexperts.css"
import "../styles/cstmcasestudiescss/smartnotes.css"
import "../styles/cstmcasestudiescss/contemporarydentistry.css"
import "../styles/cstmcasestudiescss/cwatch.css"
import "../styles/cstmcasestudiescss/ironstudio.css"
import "../styles/cstmcasestudiescss/pairdesi.css"
import "../styles/cstmcasestudiescss/cigarsnearme.css"
import "../styles/cstmcasestudiescss/souleway.css"
import "../styles/cstmcasestudiescss/medicationtracker.css"
import "../styles/cstmcasestudiescss/squreonemedia.css"

import PDFComPro from "../images/decodeup-company-profile-v4.pdf"

// Slider
import "flickity/dist/flickity.min.css"

const Layout = ({ children, sendStateToLayout, closeHideshow }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShouldAnimate(true)
    }, 100)
  }, [])

  useEffect(() => {
    const hashq = window.location.hash.substring(1)
    if (hashq === "company-profile") {
      const a = document.createElement("a")
      a.href = PDFComPro
      a.download = "decodeup-company-profile-v4.pdf"
      document.body.appendChild(a)
      a.click()
      window.location.href = "/"
      return a
    }
  }, [])

  const sendStateToHeader = isThemefromHeader => {
    if (sendStateToLayout) sendStateToLayout(isThemefromHeader)
  }

  return (
    <>
      <div className="relative z-10 bg-background-primary overflow-x-hidden">
        <div onClick={closeHideshow}>
          <Notice />
        </div>
        {!shouldAnimate && !shouldAnimate ? (
          <div className="min-h-screen"> </div>
        ) : (
          <div onClick={closeHideshow}>
            <Header sendStateToHeader={sendStateToHeader} />
          </div>
        )}
        {/* <main className="-top-20 relative overflow-hidden"> */}
        <main className="relative overflow-hidden">{children}</main>
        <div>
          <div className="relative z-1" onClick={closeHideshow}>
            <Footer />
          </div>
          <div onClick={closeHideshow}>
            <Location>
              {({ location }) => location.pathname === "/" && <MoreInfo />}
            </Location>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
