import React, { useRef, useState } from "react"
import Axios from "axios"

const NewsLetter = () => {
    const [email, setEmail] = useState("")
    const [isShown, setIsShown] = useState(false)
    const [isShownSuccessbtn, setIsShownSuccessbtn] = useState(false)
    const [isShownMainbtn, setIsShownMainbtn] = useState(false)
    const [isHide, setIsHide] = useState(false)
    const [isDisable, setIsDisable] = useState(true)
    const [errMessage, setErrMessage] = useState("")
    const inputRef = useRef(null);

    function handleClick() {
        setIsShown(true)
        setIsShownMainbtn(true)
        inputRef.current.focus();
    }
    function handleSubmit(e) {
        e.preventDefault();
        setIsDisable(true)
        Axios.post(`${process.env.API_LINK}api/newsletter/create`, { "email": email })
            .then(result => {
                if (result.data.status) {
                    setIsShown(false)
                    setIsHide(true)
                    setIsShownSuccessbtn(true)
                    setTimeout(() => {
                        setIsShownMainbtn(false)
                        // setIsShownSuccessbtn(false)
                        setIsHide(false)
                        setEmail("")
                    }, 1450);
                    setTimeout(() => {
                        setIsShownSuccessbtn(false)
                    }, 1000);
                } else {
                    setErrMessage("Something went wrong try again!!!")
                }
            })
            .catch(err => {
                setErrMessage(err)
            })


    }
    function handleChange(e) {
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const value = e.target.value;
        if (emailPattern.test(value)) {
            setEmail(value)
            setIsDisable(false)
        } else {
            setEmail(value)
            setIsDisable(true)
        }
    }
    return (
        <>
            <div className={`${isShown ? "expand-b" : ""} ${isHide ? "hide" : ""} news-letter-container`}>
                <button type="button" id="trigger" className={`${isShownMainbtn ? "" : "shown"} news-letter--btn w-full z-30 font-poppings-bold text-rh5 lg:text-h5 text-cyan absolute inset-0 transform scale-0 opacity-0`} onClick={handleClick}>
                    Sign Up for Newsletter
                </button>
                <form id="news-letter-form" onSubmit={handleSubmit}>
                    <div id="news-letter--input" className="z-20 absolute inset-0 transform scale-100 opacity-100">
                        <input type="text" placeholder="E-mail" name="email" value={email} onChange={handleChange} ref={inputRef} className={`${isShown ? "shown" : ""} ${isHide ? "hide" : ""} font-poppings-normal text-p3 placeholder-text-term-secondary text-term-primary`} />
                        <button type="submit" disabled={isDisable} className={`${isShown ? "shown" : ""} ${isHide ? "hide" : ""} w-1/5 font-poppings-bold text-rh5 lg:text-h5 text-white bg-cyan`}>
                            Send
                        </button>
                    </div>
                    <div id="success" className={`${isShownSuccessbtn ? "shown" : ""} flex justify-center items-center z-10 font-poppings-bold text-rh5 lg:text-h5 text-cyan absolute inset-0 transform scale-0 opacity-0`}>
                        Thank you!
                    </div>
                </form>
            </div>
            <p>{errMessage}</p>
        </>
    )
}

export default NewsLetter