import React from "react";
import PropTypes from "prop-types";
import { Link, useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import NewsLetter from "./news-letter";
import { Fade } from 'react-reveal'

// Images
import Logo from "../images/uploads/decodeup-logo-D.svg"
import PDFComPro from "../images/decodeup-company-profile-v4.pdf"

const activeStyles = {
  color: '#00AEEF'
}


const Footer = () => {

  const downloadCompanyProfilePDF = () => {

    const a = document.createElement('a')
    a.href = PDFComPro
    a.download = 'decodeup-company-profile-v4.pdf'
    document.body.appendChild(a)
    a.click()
    return a
  }


  const data = useStaticQuery(graphql`
    query {
      content: markdownRemark(fileAbsolutePath: {regex: "/footer.md/"}) {
        frontmatter {
          other_info {
            copyright
            email
            other_info
            telephone
            other_telephone
          }
          socialLinks {
            facebook
            linkedin
            twitter
          }
        }
      }
    }
  `)
  const info = data.content.frontmatter;
  return (
    <>
      <footer className="bg-background-primary">
        <div className="pt-42 pb-55 lg:pt-51 lg:pb-50 container mx-auto">
          <div className="block lg:flex flex-wrap justify-between items-center">
            <Fade bottom distance="60px" delay={300}>
              <div className="block lg:flex items-center">
                <div className="pb-5-1 lg:pb-0 lg:pr-33 xl:pr-1-09 2xl:pr-1-19">
                  <Link to="/" className="inline-block">
                    <img className="w-69 h-54 md:w-81 md:h-63" src={Logo} alt="DecodeUp logo" />
                    {/* <GatsbyImage
                      className="w-69 h-54 md:w-81 md:h-63"
                      image={getImage(info.logoImage.image)}
                      alt={info.logoImage.alt}
                    /> */}
                  </Link>
                </div>
                <div className="pb-6 lg:pb-0">
                  <ul className="font-worksans-normal flex flex-wrap mms:justify-between">
                    <li className="py-1-3 lg:py-0 pr-35 mms:pr-0 sm:px-0 lg:pr-6-3 xl:pr-37">
                      <Link
                        to="/verticals"
                        activeStyle={activeStyles}
                        className="text-p4 lg:text-p2 text-term-primary hover:text-cyan" >
                        Verticals
                      </Link>
                    </li>
                    {/* <li className="py-1-3 lg:py-0 pr-35 mms:pr-0 sm:px-0 lg:pr-6-3 xl:pr-37">
                      <Link
                        to="/blog"
                        activeStyle={activeStyles}
                        className="text-p4 lg:text-p2 text-term-primary hover:text-cyan" >
                        Blog
                      </Link>
                    </li> */}

                     <li className="py-1-3 lg:py-0 pr-35 mms:pr-0 sm:px-0 lg:pr-6-3 xl:pr-37">
                      <Link
                        to="/blog"
                        activeStyle={activeStyles}
                        className="text-p4 lg:text-p2 text-term-primary hover:text-cyan">
                        Blog
                      </Link>
                    </li>
                    <li className="py-1-3 lg:py-0 pr-44 mms:pr-0 sm:px-0 lg:pr-6-3 xl:pr-37">
                      <Link
                        to="/technologies"
                        activeStyle={activeStyles}
                        className="text-p4 lg:text-p2 text-term-primary hover:text-cyan">
                        Technologies
                      </Link>
                    </li>
                    <li className="py-1-3 lg:py-0 pr-35 mms:pr-0 sm:px-0 lg:pr-6-3 xl:pr-37">
                      <Link
                        to="/portfolio"
                        activeStyle={activeStyles}
                        className="text-p4 lg:text-p2 text-term-primary hover:text-cyan" >
                        Portfolio
                      </Link>
                    </li>
                    <li className="py-1-3 lg:py-0 pr-44 mms:pr-0 sm:px-0 lg:pr-6-3 xl:pr-37">
                      <Link
                        to="/job-opening"
                        activeStyle={activeStyles}
                        className="text-p4 lg:text-p2 text-term-primary hover:text-cyan">
                        Jobs
                      </Link>
                    </li>
                    <li className="py-1-3 lg:py-0 pr-44 mms:pr-0 sm:px-0 lg:pr-6-3 xl:pr-37">
                      <Link
                        to="/faq"
                        activeStyle={activeStyles}
                        className="text-p4 lg:text-p2 text-term-primary hover:text-cyan" >
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Fade>
            <Fade bottom distance="60px" delay={300}>
              <div className="opacity-100 transform translate-y-0">
                <NewsLetter />
              </div>
            </Fade>
          </div>
          <div className="mt-6-6 lg:mt-6-5 mb-35 lg:mb-37 border-b border-tone-primary"></div>
          <div>
            <div className="flex flex-wrap justify-between">
              <div className="font-worksans-normal w-full md:w-3/4 lg:w-5/6">
                <Fade bottom distance="60px" delay={300}>
                  <p className="flex flex-wrap w-full pb-3-1 lg:pb-3-3 text-rp3 lg:text-p3 text-term-primary">
                    <a
                      href={`tel:${info.other_info.other_telephone}`}
                      className="nav-family"
                    >
                      {info.other_info.other_telephone}
                    </a>
                    <span className="px-2">|</span>
                    {/* {" | "} */}
                    <a
                      href={`tel:${info.other_info.telephone}`}
                      className="nav-family"
                    >
                      {info.other_info.telephone}
                    </a>
                    <span className="px-2">|</span>
                    {/* {" | "} */}
                    <a
                      href={`mailto:${info.other_info.email}`}
                      className="nav-family"
                    >
                      {info.other_info.email}
                    </a>
                  </p>
                </Fade>
                <Fade bottom distance="60px" delay={300}>
                  <div className="xs:w-3-00 ss:w-full lg:w-full flex items-center flex-wrap ">

                    <p className="text-rp5 lg:text-p4 text-term-primary opacity-60 ">
                      © {new Date().getFullYear()} {info.other_info.copyright}
                      <br className="block ss:+hidden">
                      </br>
                    </p>
                    <div className="flex items-center text-rp5 lg:text-p4 text-term-primary opacity-60 ">
                      {/* Nayan has comment out download file features when click on profile link from the footer section  */}
                      {/* <Link target="_blank" to='/' onClick={downloadCompanyProfilePDF} className="pt-2-2 pb-2-2 lg:p-0 ss:ml-6 underline"> */}
                      <Link to='/' className="pt-2-2 pb-2-2 lg:p-0 ss:ml-6 underline">
                        Profile
                      </Link>
                      <Link to="/privacy-policy" className="pt-2-2 pb-2-2 lg:p-0 ml-6 underline">
                        Privacy
                      </Link>
                      <Link to="/" className="pt-2-2 pb-2-2 lg:p-0 ml-6 underline">
                        Terms of use
                      </Link>
                    </div>
                  </div>
                </Fade>
                <Fade bottom distance="60px" delay={300}>
                  <div className="text-term-primary xs:w-3-00 ss:w-full lg:w-full text-rp5 lg:text-p4 text-term-primary">
                    <p className="text-term-primary xs:w-3-00 ss:w-full lg:w-full text-rp5 lg:text-p4 text-term-primary opacity-60 ">
                      {info.other_info.other_info}
                    </p>
                  </div>
                </Fade>
              </div>
              <div className="pt-33 md:pt-0 h-6 flex">
                <Fade bottom distance="60px" delay={300}>
                  <div>
                    <a href={info.socialLinks.twitter} className="inline-block text-2xl text-term-primary opacity-20 hover:cursor-pointer hover:opacity-100 transform transition duration-500 ease">
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faTwitter}
                      />
                    </a>
                  </div>
                </Fade>
                <Fade bottom distance="60px" delay={300}>
                  <div>
                    <a href={info.socialLinks.linkedin} className="inline-block ml-6-7 mr-36 lg:ml-34 lg:mr-39  text-2xl text-term-primary opacity-20 hover:cursor-pointer hover:opacity-100 transform transition duration-500 ease">
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faLinkedinIn}
                      />
                    </a>
                  </div>
                </Fade>
                <Fade bottom distance="60px" delay={300}>
                  <div>
                    <a href={info.socialLinks.facebook} className="inline-block text-2xl text-term-primary opacity-20 hover:cursor-pointer hover:opacity-100 transform transition duration-500 ease">
                      <FontAwesomeIcon
                        className="font-awesome"
                        icon={faFacebookF}
                      />
                    </a>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
