
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"

const Notice = () => {
  const data = useStaticQuery(graphql`
    query {
      content: markdownRemark(fileAbsolutePath: {regex: "/notice.md/"}) {
        frontmatter {
          notice_status
          notice_message
        }
      }
    }
  `)
  const notice_msg = data.content.frontmatter.notice_message
  const notice_status = data.content.frontmatter.notice_status
  // const [isVisible, setIsVisible] = useState(data.content.frontmatter.notice_status)
  const [show, setShow] = useState(false);
  useEffect(() => {
    function initialFunction() {
      if (notice_status) {
        const storeData = localStorage.getItem("notice-alert");
        if (storeData) {
          setShow(storeData)
        } else {
          setShow(true)
          localStorage.setItem("notice-alert", "true");
        }
      } else {
        setShow(false)
        localStorage.removeItem("notice-alert");
      }

    }
    initialFunction()
  });

  function hideNotice() {
    document.querySelector('.notice-bar').style.height = "0";
    setShow(false)
    localStorage.setItem("notice-alert", "false");
  };

  return (
    <>
      <div className="z-20 bg-dark-grey relative notice-bar" data-notice-bar={show}>
        <div className="py-2 lg:py-5-1 notice-bar-content">
          <div className="flex pl-3-2 ms:pl-0 ms:justify-center items-center ss:text-center">
            <span className="py-1 pl-4 pr-3-3 lg:pt-0-3 lg:pb-1-1 lg:px-3 items-center text-p4 lg:text-p3 text-dark-blue bg-white font-worksans-normal">
              NOTICE
            </span>
            <p className="pl-3-2 lg:pl-4-3 text-p4 lg:text-p3 text-white items-center font-worksans-normal">
              {notice_msg}
            </p>
          </div>
          <button onClick={hideNotice} className="notice-bar-close focus:outline-none">
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.6">
                <path
                  d="M19.0922 6.60658L6.36426 19.3345"
                  stroke="white"></path>
                <path
                  d="M19.0922 19.3345L6.36426 6.6066"
                  stroke="white"></path>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}

export default Notice;